* {
  box-sizing: border-box;
}

.display-flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.search-row {
  margin: 2%;
}

.result-col {
  width: 25%;
  overflow: auto;
}

.max-height-25 {
  max-height: 25vh;
}

.marign-bottom {
  margin-bottom: 1%;
}

.dialog-text-area {
  width: 70vh;
  height: 60vh;
  border: none;
  overflow: auto;
  resize: none;
}

.margin-auto {
  margin: auto;
}

.modal-full-width {
  width: 150vh;
}

.modal-half-width {
  width: 75vh;
}

.note-alert-color {
  color: red;
}

.note-success-color {
  color: green;
}

.nowrap {
  white-space: nowrap;
}

.inputField {
  width: 30%;
  margin-right: 2%;
}

.margin-right {
  margin-right: 2%;
}

.permissionCard {
  margin-bottom: 5vh;
  max-height: 40vh;
  overflow: scroll;
}

.appform {
  width: 40%;
}
