.row {
  width: 60%;
  padding: 2.3vh;
}

.textarea {
  width: 100%;
  height: 8vh;
}

.tabContainer {
  width: 100%;
}

#notificationInput {
  width: 100%;
}

#generateButton {
  width: 0%;
}

#modal {
  height: 80vh;
}

#resultcontainer {
  overflow: scroll;
  height: 85vh;
}

#resultCardContainer {
  margin: 18px 0px;
  width: 38%;
}

#customPayload {
  height: 65vh;
}

#resultHeader {
  width: 100%;
  display: inline-block;
}

#clearButton {
  float: right;
}

.rightAlign {
  float: right;
}

#h4text {
  float: left;
}

.tabPanel {
  height: 90vh;
  overflow: auto;
}

.borderRadius {
  border-radius: 3% !important;
}
